import React from 'react';
import LayoutTextPage from '../layout/layoutTextPage';
import { mq, theme } from '../theme';

class ContactPage extends React.PureComponent<{}, {}> { 
    public render() {
        return (
            <LayoutTextPage title='Contact'>
                <div
                    css={mq({
                    maxWidth:'90vw',
                    margin:'auto',
                })}
                >
                    <div>Hier komen de Contact pagina te staan</div>
                </div>   
        </LayoutTextPage>
        )}
}

export default ContactPage;
